import * as React from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import Layout from "../../components/layout/layout"
import Text from "../../components/Text/Text"
import Title from "../../components/Title/Title"
import Button from "../../components/Button/Button"
import * as ExamStyles from "./eye-examinations.module.css"
import Accordion from "../../components/Accordion/Accordion"
import TxtImg from "../../components/TxtImg/TxtImg"
import { StaticImage } from "gatsby-plugin-image"

function Exams({ data }) {
    return (
        <Layout pageTitle="Eye Examinations">
            <Helmet>
                <meta name="description" content="Giving your eyecare the time, attention and expertise it deserves. Offering comprehensive eye examinations, content lens fitting and checks, child examinations and more." />
            </Helmet>
            <section>
                <Title>
                    <h1>Eye Examinations</h1>
                    <p>Giving your eyecare the time, attention and expertise it deserves.</p>
                    <StaticImage src="../../images/trial-frame.png" alt="frames illustration" width="250" />
                </Title>
            </section>
            <section className={ExamStyles.servicesSection}>
                <Text>
                    <h2 id="eye-exam">Comprehensive eye examination</h2>
                    <p>Each examination is as unique as the patient; tests will be performed to suit your individual needs, symptoms or specific visual requirements.</p>
                    <h3>Vision testing</h3>
                    <p>A digital letter chart is used for the greatest flexibility of testing. A traditional trial frame with high quality glass lenses are used to determine your prescription as these are most like a finished pair of glasses.</p>
                    <h3>Eye examination</h3>
                    <p>A high-powered slit lamp biomicroscope is used to examine the health of your eyes. This enables a detailed and clear view of both the outside and inside of your eyes. The slit lamp is fitted with an additional viewing eyepiece that allows a high-resolution camera to be attached to the microscope in order to photograph or film the health of your eyes.</p>
                    <h3>General health</h3>
                    <p> Your eyes provide a unique insight to your general health, particularly the cardiovascular system. As well as a detailed examination of the retinal blood vessels, you will have your blood pressure and pulse oximetry measured to give a better insight into your general health. On average, people attend to see their optometrist much more frequently than their GP, so a regular blood pressure check is very useful.</p>
                    <h3>Eye pressure</h3>
                    <p> A hospital-standard instrument is used to measure the pressure in your eyes. This test gives the most accurate reading of the pressure and doesn’t involve a puff of air being blown into your eye.</p>
                    <h3>Retinal photographs</h3>
                    <p>A specialised camera takes a full colour photograph of the back of your eye. This enables long-term monitoring of your eye health as subtle changes over time can be seen in much greater detail. The camera is also an approved model to perform the NHS Diabetic Retinopathy Screening service.</p>
                    <h3>Peripheral vision</h3>
                    <p> A hospital-standard instrument is used to assess your peripheral vision. The Humphrey Visual Field Analyser is used in hospital eye departments to monitor conditions such as diabetes and glaucoma. The instrument has specialised algorithms built-in which aid with the early detection of glaucoma. It is also possible to perform the specialised Estermann test for those requiring a DVLA peripheral vision assessment.</p>
                    <h3 style={{marginBottom: 'var(--space-4)'}}>Additional considerations</h3>
                    <Accordion heading="Second opinions, referrals & problem solving" borderTop={true}>
                        <p>If you're a patient who's looking for some peace of mind by way of a second opinion then I'd be more than happy to see you.</p>
                        <p>I do take referrals from other optometrists who would like an additional examination performing. In these cases a full report is generated following the consultation and sent back to the referring optometrist.</p>
                        <p>Some eye examinations will cover all of the checks above, but will come about because you're experiencing specific issues with your eyes. If this is the case, we'll take the time in your eye examination to investigate and exlore the problems you're having.</p>
                    </Accordion>
                    <Accordion heading="Dry & watering eyes">
                        <p>The eyelids and tear film will be assessed to establish the health of the tears and front surface of the eye. This includes determining the type of dry eye (even a watering eye can be considered to be a type of ‘dry eye’), and then devising a treatment plan to help alleviate the symptoms. This includes home treatments.</p>
                        <p>Dry eye assessments are included within a standard eye examination where appropriate.</p>
                    </Accordion>
                    
                    <h2 id="child-exam">Child examination</h2>
                    <p>Babies and children of any age can have an eye examination, and these are tailored specific to the age of the patient. Specialist non-verbal vision tests are used in the practice for determining the level of vision in small children which are the same as used in the hospital.</p>
                    <p>A range of examination equipment is also used which is suited to examining a child’s eyes; specialist ophthalmoscopes which enable a clear view but are not as invasive or intimidating. Much of the equipment is based on my experience from working in the paediatric clinics at the hospital eye service.</p>
                </Text>
            </section>
            <section className={ExamStyles.bannerSection}>
                <TxtImg
                    imgFirst={false}
                    split="half"
                    imgSrc={data.glasses}
                    imgAlt="Traditional trial frame illustration"
                    removeShadow={true}
                >
                    <h2>Make an appointment</h2>
                    <p>To arrange an appointment or to discuss any questions you have, head to the contact page to get in touch.</p>
                    <Button to="/contact" external={false} type="primarySolid" margin="var(--space-5) 0 0 0">Get in touch</Button>
                </TxtImg>
            </section>
        </Layout>
    )
}

export const query = graphql`
    query {
        glasses: file(relativePath: {eq: "trial-frame.png"}) {
            childImageSharp {
                gatsbyImageData(
                    width: 800,
                    placeholder: BLURRED,
                    formats: WEBP
                )
            }
        }
    }
`

export default Exams